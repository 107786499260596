/**
 * Load ES2015 Shims if necessary
 */

(function(root){
	/* Object.assign Shim via lodash/Underscore */
	if (!('assign' in root.Object)) {
		root.Object.assign = (target, ...sources) => _.extend(target, ...sources);
	}
})(window);
